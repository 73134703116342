import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { GetIncidentsImportResponse } from '../incidents-import.model';
import { IncidentsImportService } from '../incidents-import.service';
import { IncidentImportSignalrService } from '../incident-import-signalr.service';

@Component({
  selector: 'app-incidents-import-list',
  templateUrl: './incidents-import-list.component.html',
  styleUrls: ['./incidents-import-list.component.scss'],
})
export class IncidentsImportListComponent implements OnInit {
  constructor(
    private router: Router,
    private service: IncidentsImportService,
    private signalrService: IncidentImportSignalrService
  ) {}

  ButtonColorEnum = ButtonColorEnum;
  imports: GetIncidentsImportResponse[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  isLoading: boolean = false;

  ngOnInit(): void {
    this.get();

    this.signalrService.hubConnection.on(
      'sendnotification',
      (data: GetIncidentsImportResponse) => {
        this.updateIncidentsImportResponseList(data);
      }
    );

    this.signalrService.startConnection();
  }

  updateIncidentsImportResponseList(data: GetIncidentsImportResponse) {
    if (!data) return;

    let existent = this.imports.find((i) => i.id === data.id);

    if (existent !== undefined) {
      existent.processedQty = data.processedQty;
      existent.requestQty = data.requestQty;
      existent.status = data.status;
    }
  }

  create() {
    this.router.navigate(['/incidents-import/new']);
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.imports = [];
    this.get();
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.imports = [];

    const filters: any = {};

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.service
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;

          if (data.items && data.items.length > 0) {
            this.imports = data.items.map((i) => ({
              id: i.id,
              createdOn: i.createdOn,
              client: i.client,
              cell: i.cell,
              subCell: i.subCell,
              status: i.status,
              processedQty: i.processedQty,
              requestQty: i.requestQty,
            }));
          }
        },
        error: () => {
          this.imports = [];
        },
      });
  }
}
