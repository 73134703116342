<div class="user-item">
  <div class="user-item__column">
    <strong>{{ data.createdOn | date : "dd/MM/yyyy" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.client.name || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.cell || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.subCell || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.processedQty || "-" }}/{{ data.requestQty || "-" }}</strong>
  </div>
  <div class="user-item__column status">
    <strong
      [ngClass]="{
        green: data.status == 'Finalizada',
        yellow: data.status == 'Em Andamento',
        grey: data.status == 'Em Fila'
      }"
      >{{ data.status || "-" }}</strong
    >
  </div>
</div>
