import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { GetClientsResponse } from 'src/app/pages/client/models/client.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { GetIncidentsImportResponse } from '../../incidents-import.model';

@Component({
  selector: 'app-incidents-import-list-item',
  templateUrl: './incidents-import-list-item.component.html',
  styleUrls: ['./incidents-import-list-item.component.scss'],
})
export class IncidentsImportListItemComponent {
  constructor(private router: Router, private storageService: StorageService) {}

  ButtonColorEnum = ButtonColorEnum;
  showEditUserModal: boolean = false;
  userId: string = '';

  @Input() data: GetIncidentsImportResponse = {
    id: '',
    createdOn: '',
    client: {} as GetClientsResponse,
    cell: '',
    subCell: '',
    status: '',
    processedQty: 0,
    requestQty: 0,
  };

  @Output() import: EventEmitter<string> = new EventEmitter<string>();
}
