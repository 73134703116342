import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateIncidentsImportRequest,
  GetAudiosIncidentsImportRequest,
  GetIncidentsImportRequest,
  GetPagedIncidentsImportResponse,
} from './incidents-import.model';
import { serializeQueryParams } from 'src/app/service-helper';

@Injectable({
  providedIn: 'root',
})
export class IncidentsImportService {
  private baseURL: string = environment.apiUrl + '/api/AudioImportControl';

  constructor(private http: HttpClient) {}

  get(
    filter: GetIncidentsImportRequest
  ): Observable<GetPagedIncidentsImportResponse> {
    const params = serializeQueryParams(filter);

    return this.http.get<GetPagedIncidentsImportResponse>(this.baseURL, {
      params,
    });
  }

  post(request: CreateIncidentsImportRequest): Observable<any> {
    return this.http.post<any>(this.baseURL, request);
  }

  getAudios(request: GetAudiosIncidentsImportRequest): Observable<any> {
    const params = serializeQueryParams(request);

    return this.http.get<any>(this.baseURL + '/Count', {
      params,
    });
  }
}
