import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetSkillRequest,
  GetSkillResponse,
} from 'src/app/pages/client/models/client.model';
import { serializeQueryParams } from 'src/app/service-helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  private baseURL: string = environment.apiUrl + '/api/Skill';

  constructor(private http: HttpClient) {}

  get(filter: GetSkillRequest): Observable<GetSkillResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<GetSkillResponse[]>(this.baseURL, { params });
  }

  getCells(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + '/cells');
  }

  getSubCells(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + '/subcells');
  }
}
