import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { StatusEnum } from '../../../../common/utils/status-enum.models';
import { GetUsersFilter } from '../../service/models/user.service.model';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent {
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    private storageService: StorageService
  ) {}

  @Output() filterEmmiter: EventEmitter<GetUsersFilter> =
    new EventEmitter<GetUsersFilter>();

  form = this.formBuilder.group({
    name: '',
    email: '',
    cell: '',
    functional: '',
    status: [[]],
    profiles: [[]],
    initialDate: [''],
    finalDate: [''],
    recorderId: '',
    supervisor: '',
    isInconsistent: null,
  });

  hasSupervisorRole: boolean = false;

  isOpen: boolean = false;
  isLoading: boolean = false;

  status: string[] = [];
  selectedStatus: string[] = [];

  profiles: string[] = [];
  selectedProfiles: string[] = [];

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;
  MaskTypeEnum = MaskTypeEnum;

  isInconsistentOptions = ['Sim', 'Não'];

  ngOnInit() {
    this.hasSupervisorRole =
      this.storageService.getRole() === UserRoleEnum.supervisor;
    this.populateStatus();
    this.populateProfiles();
  }

  populateStatus() {
    this.status = [StatusEnum.ATIVO, StatusEnum.INATIVO];
  }

  populateProfiles() {
    this.userService.getRoles().subscribe({
      next: (roles) => {
        this.profiles = roles;
      },
    });
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  clear() {
    this.form.reset();
    this.selectedStatus = [];
    this.selectedProfiles = [];
  }

  search() {

    var isInconsistent = null;

    if (this.form.controls.isInconsistent.value == "Sim")
      isInconsistent = true;
    else if (this.form.controls.isInconsistent.value == "Não")
      isInconsistent = false;

    let filter: GetUsersFilter = {} as GetUsersFilter;

    filter.name = this.form.controls.name.value ?? '';
    filter.email = this.form.controls.email.value ?? '';
    filter.functional = this.form.controls.functional.value ?? '';
    filter.status = this.form.controls.status.value ?? null;
    filter.profiles = this.form.controls.profiles.value ?? null;
    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';
    filter.recorderId = this.form.controls.recorderId.value ?? '';
    filter.supervisorName = this.form.controls.supervisor.value ?? '';
    filter.isInconsistent = isInconsistent;

    this.filterEmmiter.emit(filter);

    this.isOpen = false;
  }
}
