import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from './notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private baseURL: string = `${environment.apiUrl}/api/Notification`;

  constructor(private http: HttpClient) {}

  get(): Observable<Notification[]> {
    return this.http.get<Notification[]>(this.baseURL);
  }

  update(id: string) {
    return this.http.put(this.baseURL + '/' + id, null);
  }
}
