import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../common/services/storage/storage.service';
import { ButtonColorEnum } from '../shared/buttons/enums/button-color.enum';
import { NotificationService } from './notification.service';
import { Notification } from './notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(
    private service: NotificationService,
    private toastr: ToastrService,
    private storageService: StorageService,
    private router: Router
  ) {}

  ButtonColorEnum = ButtonColorEnum;
  isOpen: boolean = false;
  loading: boolean = false;
  notifications: Notification[] = [];
  role: string = '';
  icon: string = 'assets/icons/notification-bell.svg';
  firstOpen: boolean = true;

  ngOnInit() {
    this.getItems();
    this.role = this.storageService.getRole();
  }

  toggle() {
    if (!this.isOpen) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.getItems();
  }

  updateIcon() {
    if (this.notifications.length > 0) {
      this.icon = 'assets/icons/notification-bell-alert.svg';
    } else {
      this.icon = 'assets/icons/notification-bell.svg';
    }
  }

  getItems() {
    this.loading = true;
    this.service.get().subscribe({
      next: (data) => {
        this.notifications = data;

        this.updateIcon();

        if (this.firstOpen) this.firstOpen = false;
        else {
          if (this.notifications.length > 0) this.isOpen = true;
          else this.toastr.error('Não há notificações no momento.');
        }

        this.loading = false;
      },
      error: () => {
        this.loading = false;

        this.toastr.error('Não foi possível carregar a lista de notificações');
      },
    });
  }

  markAsRead(id: string) {
    var notification = this.notifications.find((n) => n.id == id);

    if (notification) notification.loading = true;
    else {
      this.toastr.error('Não foi possível marcar a notificação como lida.');
      return;
    }

    this.service.update(id).subscribe({
      next: () => {
        this.toastr.success('Notificação marcada como lida.');
        if (notification) notification.loading = false;
        this.notifications = this.notifications.filter((n) => n.id != id);
        this.updateIcon();
      },
      error: () => {
        this.toastr.error('Não foi possível marcar a notificação como lida.');
        if (notification) notification.loading = false;
      },
    });
  }

  close() {
    if (!this.notifications.some((n) => n.loading)) this.isOpen = false;
  }

  getIcon() {
    return this.icon;
  }
}
