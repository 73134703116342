import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BigNumbersResponse,
  ContactReasonsResponse,
  DailyNonTalkResponse,
  DailyTMAResponse,
  DashboardFilterRequest,
  IncidentShiftsResponse,
  TMAXNonTalkBySegmentResponse,
  TopMentionedCategoriesResponse,
  TopMentionedKeywordsResponse,
  TranscriptionsByPeriodResponse,
} from './models/dashboard.service.model';
import { serializeQueryParams } from 'src/app/service-helper';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private baseURL: string = `${environment.apiUrl}/api/Dashboard`;

  constructor(private http: HttpClient) {}

  getTranscriptionsByPeriod(
    filter: DashboardFilterRequest
  ): Observable<TranscriptionsByPeriodResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<TranscriptionsByPeriodResponse[]>(
      this.baseURL + '/TranscriptionsByPeriod',
      { params }
    );
  }

  getTopMentionedContacReasons(
    filter: DashboardFilterRequest
  ): Observable<ContactReasonsResponse> {
    const params = serializeQueryParams(filter);
    return this.http.get<ContactReasonsResponse>(
      this.baseURL + '/ContactsReason',
      { params }
    );
  }

  getTopMentionedCategories(
    filter: DashboardFilterRequest
  ): Observable<TopMentionedCategoriesResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<TopMentionedCategoriesResponse[]>(
      this.baseURL + '/MentionedCategoriesRanking',
      { params }
    );
  }

  getTopMentionedKeywords(
    filter: DashboardFilterRequest
  ): Observable<TopMentionedKeywordsResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<TopMentionedKeywordsResponse[]>(
      this.baseURL + '/MentionedKeywordsRanking',
      { params }
    );
  }

  getTmaXNonTalkBySegment(
    filter: DashboardFilterRequest
  ): Observable<TMAXNonTalkBySegmentResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<TMAXNonTalkBySegmentResponse[]>(
      this.baseURL + '/TmaXNonTalkBySegment',
      { params }
    );
  }

  getDailyTma(filter: DashboardFilterRequest): Observable<DailyTMAResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<DailyTMAResponse[]>(this.baseURL + '/DailyTMA', {
      params,
    });
  }

  getDailyNonTalk(
    filter: DashboardFilterRequest
  ): Observable<DailyNonTalkResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<DailyNonTalkResponse[]>(
      this.baseURL + '/DailyNonTalk',
      {
        params,
      }
    );
  }

  getIncidentShifts(
    filter: DashboardFilterRequest
  ): Observable<IncidentShiftsResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<IncidentShiftsResponse[]>(
      this.baseURL + '/IncidentShifts',
      {
        params,
      }
    );
  }

  getBigNumbers(
    filter: DashboardFilterRequest
  ): Observable<BigNumbersResponse> {
    const params = serializeQueryParams(filter);
    return this.http.get<BigNumbersResponse>(this.baseURL + '/BigNumbers', {
      params,
    });
  }
}
