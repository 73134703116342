import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetKeywordsCloudRequest, GetKeywordsCloudResponse } from './keywords-cloud.model';
import { serializeQueryParams } from 'src/app/service-helper';

@Injectable({
  providedIn: 'root',
})
export class CloudKeywordsService {
  constructor(private http: HttpClient) {}

  private baseURL: string = `${environment.apiUrl}/api/CloudKeywords`;

  getAnalytic(
    filter: GetKeywordsCloudRequest
  ): Observable<GetKeywordsCloudResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<GetKeywordsCloudResponse[]>(this.baseURL + "/Analytic", {
      params,
    });
  }

  getContactReason(
    filter: GetKeywordsCloudRequest
  ): Observable<GetKeywordsCloudResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<GetKeywordsCloudResponse[]>(this.baseURL + "/ContactReason", {
      params,
    });
  }

  getOffenders(
    filter: GetKeywordsCloudRequest
  ): Observable<GetKeywordsCloudResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<GetKeywordsCloudResponse[]>(this.baseURL + "/Offender", {
      params,
    });
  }
}
