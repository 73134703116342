import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CategoryAnalysisReportGetFilter,
  PagedCategoryAnalysisReportGetResponse,
} from './models/category-analysis-report.service.model';
import { KeywordCloudResponse } from './models/keyword-cloud.service.model';
import { serializeQueryParams } from 'src/app/service-helper';

@Injectable({
  providedIn: 'root',
})
export class CategoryAnalysisReportService {
  constructor(private http: HttpClient) {}

  private baseURL: string = `${environment.apiUrl}/api/MentionedCategoriesReport`;

  get(
    filter: CategoryAnalysisReportGetFilter
  ): Observable<PagedCategoryAnalysisReportGetResponse> {
    const params = serializeQueryParams(filter);
    return this.http.get<PagedCategoryAnalysisReportGetResponse>(this.baseURL, {
      params,
    });
  }

  getKeywordCloud(
    filter: CategoryAnalysisReportGetFilter
  ): Observable<KeywordCloudResponse[]> {
    const params = serializeQueryParams(filter);
    return this.http.get<KeywordCloudResponse[]>(
      this.baseURL + '/CloudKeywords',
      { params }
    );
  }

  export(filters: CategoryAnalysisReportGetFilter) {
    return this.http.post(this.baseURL, filters, { responseType: 'blob' });
  }
}
