<div (clickOutside)="close()">
  <app-icon-button
    [icon]="getIcon()"
    [color]="ButtonColorEnum.white"
    (clickFunc)="toggle()"
    [loading]="loading"
  ></app-icon-button>

  <div class="notifications-container">
    <ng-container *ngFor="let notification of notifications; let i = index">
      <div *ngIf="isOpen" class="notification">
        <div class="message">
          <img
            *ngIf="!notification.loading"
            src="assets/icons/alert-line.svg"
            class="icon"
          />
          <span *ngIf="!notification.loading" (click)="markAsRead(notification.id)">{{
            notification.message
          }}</span>
          <div *ngIf="notification.loading" class="loading-container">
            <div class="loading-animation"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
