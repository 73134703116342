import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateCategoryRequest,
  DuplicateCategoryRequest,
  GetCategoriesFilter,
  GetCategoriesResponse,
  PagedGetCategoriesResponse,
  UpdateCategoryRequest,
} from './models/category.service.model';
import { serializeQueryParams } from 'src/app/service-helper';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private baseURL: string = `${environment.apiUrl}/api/Category`;

  constructor(private http: HttpClient) {}

  create(request: CreateCategoryRequest) {
    return this.http.post(this.baseURL, request);
  }

  duplicate(id: string, request: DuplicateCategoryRequest) {
    return this.http.post(this.baseURL + '/duplicate/' + id, request);
  }

  get(filter: GetCategoriesFilter): Observable<PagedGetCategoriesResponse> {
    const params = serializeQueryParams(filter);
    return this.http.get<PagedGetCategoriesResponse>(this.baseURL, { params });
  }

  getAll(): Observable<GetCategoriesResponse[]> {
    return this.http.get<GetCategoriesResponse[]>(this.baseURL + '/all');
  }

  getById(id: string): Observable<GetCategoriesResponse> {
    return this.http.get<GetCategoriesResponse>(this.baseURL + '/' + id);
  }

  update(id: string, request: UpdateCategoryRequest) {
    return this.http.put(this.baseURL + '/' + id, request);
  }

  delete(id: string) {
    return this.http.delete(this.baseURL + '/' + id);
  }
}
