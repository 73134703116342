import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResetPasswordModel } from './models/reset-passwod.model';
import { UserResetPasswordModel } from './models/user-reset-password.model';
import {
  CreateUserRequest,
  GetUserPagedResponse,
  GetUserResponse,
  GetUsersFilter,
  UpdateUserRequest,
} from './models/user.service.model';
import { serializeQueryParams } from 'src/app/service-helper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseURL: string = `${environment.apiUrl}/api/User`;

  constructor(private http: HttpClient) {}

  get(filter: GetUsersFilter | null = null): Observable<GetUserPagedResponse> {
    if (filter == null) filter = {} as GetUsersFilter;

    const params = serializeQueryParams(filter);

    return this.http.get<GetUserPagedResponse>(`${this.baseURL}`, {
      params,
    });
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + '/Roles');
  }

  getById(id: string): Observable<GetUserResponse> {
    return this.http.get<GetUserResponse>(`${this.baseURL}/${id}`);
  }

  createUser(request: CreateUserRequest) {
    return this.http.post(this.baseURL, request);
  }

  lostPassword(email: string) {
    const body = {
      email: email,
      token: '',
      password: '',
    } as ResetPasswordModel;
    return this.http.post(`${this.baseURL}/reset-password`, body);
  }

  validateToken(token: string) {
    return this.http.get<UserResetPasswordModel>(
      `${this.baseURL}/${token}/reset-password`
    );
  }

  resetPassword(userId: string, resetPass: ResetPasswordModel) {
    return this.http.put(`${this.baseURL}/${userId}/reset-password`, resetPass);
  }

  alterPassword(alterPass: ResetPasswordModel) {
    return this.http.put(`${this.baseURL}/reset-password`, alterPass);
  }

  updateUser(id: string, request: UpdateUserRequest): Observable<any> {
    return this.http.put(this.baseURL + '/' + id, request);
  }
}
